export default function Sketch(p5) {
    // inspired by superfun p5.js book
    // by Nazia Fakhruddin, Haseeb Zeeshan (Author), Aqil Zeesha

    let canvas;



    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);
        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        for (let i = 0; i < 1000; i += 10) {
            if (p5.mouseX <= 100 || p5.mouseY <= 200) {
                p5.noFill();
                p5.stroke(255, 255, 255);
                p5.strokeWeight(1);
                p5.ellipse(p5.width / 2 + p5.random(-2, 2), p5.height / 2 + p5.random(-2, 2), i, i);
                p5.stroke('pink');
                p5.ellipse(p5.width / 2 + p5.random(2, -2), p5.height / 2 + p5.random(2, -2), i - 500, i - 500)
            } else {
                p5.noFill();
                p5.stroke(255, 255, 255);
                p5.strokeWeight(1);
                p5.ellipse(p5.width / 2 + p5.random(-2, 2) * p5.mouseX, p5.height / 2 + p5.random(-2, 2) * p5.mouseY, i, i);
                p5.stroke('pink');
                p5.ellipse(p5.width / 2 + p5.random(2, -2) * p5.mouseX, p5.height / 2 + p5.random(2, -2) * p5.mouseY, i - 500, i - 500)
            }
        }

    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }


}











